@import "../../../../../styles/breakpoints.scss";
@import "../../../../../styles/palette.scss";

.container {
  display: flex;
  justify-content: center;
  color: color(secondary, secondaryOne);
  background-color: white;
  padding: 100px 25px;
  @include respond-to("s") {
    padding: 200px 80px;
  }
  &.containerDark {
    color: color(primary, primaryOne);
    background-color: color(secondary, secondaryOne);
  }
  &.containerSand {
    background-color: color(sand, sandOne);
  }
  .widthBox {
    max-width: 1600px;
    width: 100%;
    position: relative;
    .title {
      display: flex;
      width: 100%;
      margin-bottom: 40px;
      @include respond-to("s") {
        margin-bottom: 80px;
      }
      @include respond-to("l") {
        max-width: 50%;
      }
    }
    .sliderWrapper {
      display: flex;
      justify-content: center;
    }
    .slider {
      display: flex;
      flex-direction: column;
      gap: 40px;
      max-width: 1200px;
      width: 100%;
      @include respond-to("s") {
        gap: 80px;
      }
      .item {
        display: flex;
        flex-direction: column;
        @include respond-to("m") {
          flex-direction: row;
        }
        .imgWrapper {
          position: relative;
          width: 100%;
          aspect-ratio: 1/1;
          @include respond-to("m") {
            width: 40%;
            margin-right: 40px;
          }
        }
        .content {
          @include respond-to("m") {
            width: 60%;
          }
        }
      }
    }
  }
}
