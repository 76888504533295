@import "../../../../styles/breakpoints.scss";

.container {
  display: flex;
  justify-content: center;
  .widthBox {
    max-width: 1600px;
    width: 100%;
    .title {
      margin: 0;
      font-weight: bold;
      font-size: 32px;
      line-height: 36px;
      @include respond-to("s") {
        font-size: 56px;
        line-height: 64px;
      }
    }
    .grid {
      margin-top: 40px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 80px 20px;
      @include respond-to("s") {
        gap: 80px 40px;
      }
      @include respond-to("l") {
        grid-template-columns: repeat(4, 1fr);
        gap: 40px;
      }
      .item {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        line-height: 24px;

        @include respond-to("s") {
          font-size: 22px;
          line-height: 33px;
        }
        p {
          margin: 0;
        }
        .imgWrapper {
          position: relative;
          aspect-ratio: 1/1;
          margin-bottom: 20px;
        }
        .name {
          text-decoration: none;
          margin-bottom: 8px;
          font-weight: bold;
          font-size: 20px;
          line-height: 25px;
          @include respond-to("s") {
            font-size: 32px;
            line-height: 40px;
          }
        }
        .email {
          margin-top: 20px;
          text-decoration: underline;
        }
        .workTitle {
          margin-bottom: 20px;
          @include respond-to("s") {
            margin-bottom: 40px;
          }
        }
      }
    }
  }
}
