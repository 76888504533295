@import "../../../../styles/breakpoints.scss";
@import "../../../../styles/palette.scss";

.sectionWrapper {
  color: color(secondary, secondaryOne);
  padding: 100px 25px;

  @include respond-to("s") {
    padding: 200px 80px;
  }

  &.backgroundWhite {
    background-color: #fff;
  }

  &.backgroundBlack {
    background-color: color(secondary, secondaryOne);
    color: color(primary, primaryOne);
  }

  &.backgroundSand {
    background-color: color(sand, sandOne);
  }
}
