@import "../../../../styles/breakpoints.scss";
@import "../../../../styles/palette.scss";

.container {
  position: relative;
  background-color: white;
  padding-bottom: 40px;

  @include respond-to("l") {
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image {
    width: 100%;
    padding-top: 100%;
    position: relative;

    @include respond-to("m") {
      width: 100vw;
      height: 100vh;
      padding: 0;
    }

    .desktopImage {
      display: none;
      @include respond-to("l") {
        height: 100%;
        display: block;
      }
    }
    .mobileImage {
      @include respond-to("l") {
        display: none;
      }
    }
  }

  .wrapper {
    @include respond-to("l") {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .boxContainer {
      max-width: 1760px;
      margin: 0 auto;
      width: 100%;
      height: 100%;

      &.mobileBox {
        @include respond-to("l") {
          display: none;
        }
      }

      @include respond-to("l") {
        position: absolute;
        display: flex;
        align-items: center;

        &.boxToRight {
          justify-content: flex-end;
          @include respond-to("l") {
            margin-left: 0;
            margin-right: 80px;
          }
        }
      }

      .box {
        position: relative;
        padding: 40px 20px 20px;
        background-color: color(sand, sandOne);
        margin: 0 20px;
        margin-top: -40px;
        @include respond-to("s") {
          margin: 0 80px;
          margin-top: -80px;
          padding: 80px 40px 40px;
        }

        @include respond-to("l") {
          position: absolute;
          max-width: 600px;
          width: 600px;
        }
        @include respond-to("l") {
          margin-left: 80px;
        }

        .title {
          font-size: 32px;
          line-height: 36px;
          font-weight: bold;
          margin-bottom: 40px;

          @include respond-to("s") {
            font-size: 56px;
            line-height: 64px;
            margin-bottom: 80px;
          }
        }
        .links {
          font-size: 16px;
          line-height: 24px;
        }

        .badge {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          top: 0;
          right: 25px;
          background-color: color(secondary, secondaryOne);
          border-radius: 0 0 40px 40px;

          @include respond-to("s") {
            width: 80px;
            height: 80px;
            right: 40px;
            font-size: 22px;
          }

          svg {
            @include respond-to("s") {
              transform: scale(2);
            }
            g {
              path {
                fill: color(primary, primaryOne);
              }
            }
          }
        }
      }
    }
  }
}
