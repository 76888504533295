@import "../../../../../styles/breakpoints.scss";
@import "../../../../../styles/palette.scss";

.container {
  display: flex;
  justify-content: center;
  color: color(secondary, secondaryOne);
  background-color: white;
  padding: 100px 0;
  @include respond-to("s") {
    padding: 200px 0;
  }
  &.containerDark {
    color: color(primary, primaryOne);
    background-color: color(secondary, secondaryOne);
  }
  &.containerSand {
    background-color: color(sand, sandOne);
  }

  .widthBox {
    max-width: 1600px;
    width: 100%;
    position: relative;
    @include respond-to("l") {
      margin: 0 80px;
    }
    .title {
      display: flex;
      width: 100%;
      margin-bottom: 40px;
      padding-left: 25px;
      @include respond-to("s") {
        padding-left: 80px;
        margin-bottom: 80px;
      }
      @include respond-to("l") {
        padding-left: 0px;
        max-width: 50%;
      }
    }
    .sliderWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn {
        display: none;
        @include respond-to("l") {
          display: flex;
        }
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        height: 48px;
        width: 48px;
        border-radius: 200px;
        border: 1px solid color(secondary, secondaryOne);
        background-color: transparent;
        transition: background-color 0.3s ease-out;
        cursor: pointer;
        svg {
          path:nth-child(2) {
            fill: color(secondary, secondaryOne);
            transition: fill 0.3s ease-out;
          }
        }
        &.btnDark {
          background-color: transparent;
          border: 1px solid color(primary, primaryOne);
          svg {
            path:nth-child(2) {
              fill: color(primary, primaryOne);
              transition: fill 0.3s ease-out;
            }
          }
        }
      }
      .btn:hover {
        background-color: color(secondary, secondaryOne);
        svg {
          path:nth-child(2) {
            fill: color(primary, primaryOne);
          }
        }
        &.btnDark {
          background-color: color(primary, primaryOne);
          svg {
            path:nth-child(2) {
              fill: color(secondary, secondaryOne);
            }
          }
        }
      }
      .slider {
        max-width: 1360px;
        width: 100%;
        .carouselItem {
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 1360px;
          padding: 0 25px;
          @include respond-to("s") {
            padding: 0 80px;
          }
          @include respond-to("m") {
            flex-direction: row;
          }
          .imgWrapper {
            position: relative;
            width: 100%;
            aspect-ratio: 1/1;
            @include respond-to("m") {
              width: 40%;
              margin-right: 40px;
            }
          }
          .content {
            text-align: start;
            position: relative;
            @include respond-to("m") {
              width: 60%;
            }
          }
        }
      }
    }
    .carouselIndicators {
      display: flex;
      justify-content: center;
      margin-top: 40px;

      .indicator {
        height: 16px;
        width: 16px;
        border: 1px solid color(secondary, secondaryOne);
        border-radius: 100%;
        margin: 0 4px;
        transition: background-color 0.3s ease-out;
        cursor: pointer;
        &.active {
          background-color: color(secondary, secondaryOne);
        }
      }

      &.dark {
        .indicator {
          border: 1px solid color(primary, primaryOne);
          &.active {
            background-color: color(primary, primaryOne);
          }
        }
      }
    }
  }
}
