@import "../../../../styles/breakpoints.scss";

.section {
  display: flex;
  justify-content: center;
  .richText {
    max-width: 800px;
    width: 100%;
    margin: 0 25px;
    @include respond-to("s") {
      margin: 0 80px;
    }
  }
  .richTextFull {
    max-width: 1600px;
    width: 100%;
    margin: 0 25px;
    @include respond-to("s") {
      margin: 0 80px;
    }
  }
}
