@import "../../../../styles/breakpoints.scss";
@import "../../../../styles/palette.scss";

.container {
  color: color(secondary, secondaryOne);
  max-width: 1600px;
  margin: 0 auto;

  .title {
    font-size: 32px;
    line-height: 36px;
    font-weight: bold;

    @include respond-to("s") {
      font-size: 56px;
      line-height: 64px;
    }

    @include respond-to("m") {
      max-width: 50%;
    }
  }

  .titleBottom {
    text-align: right;
    @include respond-to("m") {
      margin-left: auto;
    }
  }

  .items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: flex-start;
    margin: 40px 0 50px 0;
    gap: 20px;

    @include respond-to("s") {
      margin: 80px 0 100px 0;
      gap: 40px;
    }

    @include respond-to("l") {
      grid-template-columns: repeat(4, 1fr);
    }

    .item {
      position: relative;
      width: 100%;
      aspect-ratio: 1/1;
      background-color: color(sand, sandOne);
      cursor: pointer;

      &.noImage {
        .itemTitle {
          color: color(secondary, secondaryOne);
          svg {
            path:last-child {
              fill: color(secondary, secondaryOne);
            }
          }
        }
      }

      .itemTitle {
        position: absolute;
        color: white;
        font-size: 18px;
        line-height: 22px;
        font-weight: bold;
        left: 10px;
        bottom: 10px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        transition: gap 0.3s 0.1s;

        @include respond-to("s") {
          font-size: 28px;
          line-height: 30px;
          left: 20px;
          bottom: 20px;
          gap: 14px;
          max-width: 80%;
        }

        svg {
          @include respond-to("s") {
            margin-left: 6px;
            transform: scale(1.5);
          }

          path:last-child {
            fill: white;
          }
        }
      }

      &:hover {
        .itemTitle {
          gap: 4px;
          @include respond-to("s") {
            gap: 8px;
          }
        }
        &.noImage {
          background-color: color(secondary, secondaryOne);
          .itemTitle {
            color: color(primary, primaryOne);
            svg {
              path:last-child {
                fill: color(primary, primaryOne);
              }
            }
          }
        }
      }
    }

    // .itemWrapper {
    //   flex: 1;
    //   flex-grow: 1;
    //   max-width: calc(50% - 10px);
    //   min-width: 35%;

    //   @include respond-to("s") {
    //     max-width: calc(50% - 20px);
    //   }

    //   @include respond-to("m") {
    //     max-width: calc(25% - 30px);
    //     min-width: calc(25% - 30px);
    //   }
    // }
  }
}
