@import "../../../../styles/breakpoints.scss";
@import "../../../../styles/palette.scss";

.accordion {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid color(secondary, secondaryOne);
  padding: 17px 0;
  color: color(secondary, secondaryOne);

  @include respond-to("s") {
    padding: 32px 0;
  }

  &.firstItem {
    border-top: 2px solid color(secondary, secondaryOne);
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-size: 20px;
    line-height: 25px;
    font-weight: bold;

    @include respond-to("s") {
      font-size: 32px;
      line-height: 40px;
    }

    .icon {
      @include respond-to("s") {
        transform: scale(1.4);
      }
      svg {
        transition: transform 0.3s ease-in-out;
      }

      &.open {
        svg {
          transform: rotate(135deg);
        }
      }
    }
  }
}
