@import "../../../../styles/breakpoints.scss";
@import "../../../../styles/palette.scss";

.container {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;

  .title {
    font-size: 32px;
    line-height: 36px;
    font-weight: bold;
    margin-bottom: 32px;

    @include respond-to("s") {
      font-size: 56px;
      line-height: 64px;
      margin-bottom: 56px;
    }
  }
}
