@import "../../../../styles/breakpoints.scss";
@import "../../../../styles/palette.scss";

.container {
  display: flex;
  flex-direction: column;
  color: color(secondary, secondaryOne);
  max-width: 800px;
  margin: 0 auto;

  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 40px;

    @include respond-to("s") {
      font-size: 40px;
    }
  }

  .contactCard {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include respond-to("s") {
      flex-direction: row;
      gap: 0;
      align-items: center;
    }

    .photo {
      position: relative;
      width: 100%;
      padding-top: 100%;

      @include respond-to("s") {
        width: 50%;
        padding-top: 50%;
      }
    }
    .information {
      font-size: 16px;
      line-height: 24px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      @include respond-to("s") {
        width: 50%;
        font-size: 22px;
        line-height: 40px;
        padding-left: 40px;
      }

      .name {
        font-size: 20px;
        font-weight: bold;
        @include respond-to("s") {
          font-size: 32px;
        }
      }
      .workTitle {
      }
      .phone {
        margin: 16px 0;
        .tel {
          font-weight: bold;
        }
      }
      .email {
      }

      a {
        text-decoration: underline;
      }
    }
  }
}
