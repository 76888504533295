@import "../../../../styles/breakpoints.scss";
@import "../../../../styles/palette.scss";

.container {
  display: flex;
  flex-direction: column;
  color: color(secondary, secondaryOne);
  max-width: 1200px;

  @include respond-to("l") {
    flex-direction: row;
    margin: 0 auto;
    margin-top: 0;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
  }

  .titleTop {
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 40px;

    @include respond-to("s") {
      font-size: 56px;
      line-height: 64px;
      margin-bottom: 80px;
    }

    @include respond-to("l") {
      width: 50%;
      padding-right: 80px;
      margin-bottom: 0;
      position: sticky;
      top: 20rem;
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    gap: 80px;

    @include respond-to("s") {
      gap: 200px;
    }

    @include respond-to("l") {
      width: 50%;
    }

    .item.active {
      background-color: color(primary, primaryOne);
    }

    .item {
      background-color: color(sand, sandOne);
      padding: 40px 20px 20px;
      position: relative;
      transition: background-color 0.3s ease-in-out;

      @include respond-to("s") {
        padding: 80px 40px 40px;
      }

      .title {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 40px;

        @include respond-to("s") {
          font-size: 40px;
          line-height: 50px;
          margin-bottom: 80px;
        }
      }
      .description {
        font-size: 16px;
        line-height: 24px;
        @include respond-to("s") {
          font-size: 22px;
          line-height: 33px;
        }
      }

      .badge {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        top: 0;
        right: 20px;
        background-color: color(secondary, secondaryOne);
        border-radius: 0 0 40px 40px;

        @include respond-to("s") {
          width: 80px;
          height: 80px;
          right: 40px;
          font-size: 22px;
        }

        svg {
          @include respond-to("s") {
            transform: scale(2);
          }
          g {
            path {
              fill: color(primary, primaryOne);
            }
          }
        }
      }
    }
  }
}
