@import "../../../../styles/breakpoints.scss";
@import "../../../../styles/palette.scss";

.linkItem {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid color(secondary, secondaryOne);
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  line-height: 24px;

  @include respond-to("s") {
    font-size: 22px;
    line-height: 33px;
  }

  .icon {
    display: flex;
    justify-content: center;
    @include respond-to("s") {
      margin-top: 5px;
    }
    svg {
      margin-right: 10px;
      transition: margin-right 0.3s ease-out;
    }
  }
}
.linkItem:hover {
  cursor: pointer;
  svg {
    margin-right: 0px;
  }
}

.firstItem {
  border-top: 2px solid color(secondary, secondaryOne);
}
